// SelectFavoriteMeal.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiGetFavoriteMeals, apiLogFavoriteMeal } from '../api';

const SelectFavoriteMeal = ({ email, setLogUpdateCounter }) => {
  const [favoriteMeals, setFavoriteMeals] = useState([]);
  const [filteredMeals, setFilteredMeals] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { date, mealType } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadFavoriteMeals = async () => {
      try {
        const meals = await apiGetFavoriteMeals();
        setFavoriteMeals(meals);
        setFilteredMeals(meals);
      } catch (error) {
        console.error('Error fetching favorite meals:', error);
      }
    };
    loadFavoriteMeals();
  }, []);

  useEffect(() => {
    const filtered = favoriteMeals.filter(meal => 
      meal.alias.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMeals(filtered);
  }, [searchTerm, favoriteMeals]);

  const handleSelectMeal = async (mealId) => {
    try {
      await apiLogFavoriteMeal(mealId, date, mealType);
      setLogUpdateCounter(prevCounter => prevCounter + 1);
      navigate('/');
    } catch (error) {
      console.error('Error logging favorite meal:', error);
      alert('Failed to log favorite meal. Please try again.');
    }
  };

  const getTotalMacrosForMeal = (entries) => {
    return entries.reduce((total, entry) => {
      total.calories += entry.macros.calories;
      total.proteins += entry.macros.proteins;
      total.carbohydrates += entry.macros.carbohydrates;
      total.fats += entry.macros.fats;
      return total;
    }, { calories: 0, proteins: 0, carbohydrates: 0, fats: 0 });
  };

  return (
    <div className="container mt-5">
      <h2>Select Favorite Meal for {mealType}</h2>
      <input
        type="text"
        placeholder="Search favorites..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="form-control mb-3"
      />
      <div className="row header-row">
        <div className="col-md-5 col-sm-12">Meal Name</div>
        <div className="col-md-1 col-sm-6">Calories</div>
        <div className="col-md-1 col-sm-6">Proteins</div>
        <div className="col-md-1 col-sm-6">Carbs</div>
        <div className="col-md-1 col-sm-6">Fats</div>
        <div className="col-md-3 col-sm-12">Actions</div>
      </div>
      {filteredMeals.map((meal) => (
        <div key={meal.id} className="meal-section mb-4">
          <div className="row meal-header">
            <div className="col-md-5"><h3>{meal.alias}</h3></div>
            <div className="col-md-1 col-sm-6 macro-total-cell">{Math.round(getTotalMacrosForMeal(meal.entries).calories)}</div>
            <div className="col-md-1 col-sm-6 macro-total-cell">{Math.round(getTotalMacrosForMeal(meal.entries).proteins)}</div>
            <div className="col-md-1 col-sm-6 macro-total-cell">{Math.round(getTotalMacrosForMeal(meal.entries).carbohydrates)}</div>
            <div className="col-md-1 col-sm-6 macro-total-cell">{Math.round(getTotalMacrosForMeal(meal.entries).fats)}</div>
            <div className="col-md-3 col-sm-6 action-column">
              <button onClick={() => handleSelectMeal(meal.id)} className="btn btn-sm btn-primary action-button" title="Select this meal">
                Select
              </button>
            </div>
          </div>
          {meal.entries.map((entry, index) => (
            <div key={index} className="row meal-row align-items-center">
              <div className="col-md-5 col-sm-12">
                {entry.food.name} ({entry.quantity} {entry.units})
              </div>
              <div className="col-md-1 col-sm-6 macro-cell">{Math.round(entry.macros.calories)}</div>
              <div className="col-md-1 col-sm-6 macro-cell">{Math.round(entry.macros.proteins)}</div>
              <div className="col-md-1 col-sm-6 macro-cell">{Math.round(entry.macros.carbohydrates)}</div>
              <div className="col-md-1 col-sm-6 macro-cell">{Math.round(entry.macros.fats)}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SelectFavoriteMeal;