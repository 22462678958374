import React, { useState, useEffect } from 'react';
import { apiAnalyseDaylog, apiAskMrFit, apiAnalyseWeek } from '../api';
import { apiTranscribeAudio } from '../api';
import '../styles/MrFitAdvisor.css';
import voiceService from '../services/voiceService';
import AudioVisualizer from './AudioVisualizer';
import RecordingTimer from './RecordingTimer';


const MrFitAdvisor = ({ date, dayLog, totalMacros, macrosGoals }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const email = user.email;
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dailyAnalysis, setDailyAnalysis] = useState({
    improvements: []
  });
  const [weeklyAnalysis, setWeeklyAnalysis] = useState(null);
  const [isAnalysisLoading, setIsAnalysisLoading] = useState(false);
  const [isWeeklyAnalysisLoading, setIsWeeklyAnalysisLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioStream, setAudioStream] = useState(null);
  const [expandedGroups, setExpandedGroups] = useState([]);

  useEffect(() => {
    const fetchAnalysis = async () => {
      setIsAnalysisLoading(true);
      setIsWeeklyAnalysisLoading(true);
      try {
        // Obtener análisis semanal
        const weekAnalysis = await apiAnalyseWeek(date);
        setWeeklyAnalysis(weekAnalysis);

        // Obtener análisis diario
        const analysis = await apiAnalyseDaylog(date, totalMacros, macrosGoals);
        setDailyAnalysis(analysis);
      } catch (error) {
        console.error('Error getting analysis:', error);
        setDailyAnalysis({
          improvements: []
        });
      } finally {
        setIsAnalysisLoading(false);
        setIsWeeklyAnalysisLoading(false);
      }
    };

    if (dayLog && totalMacros && macrosGoals) {
      fetchAnalysis();
    }
  }, [dayLog, totalMacros, macrosGoals, date]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    setIsLoading(true);
    try {
      const response = await apiAskMrFit(date, dayLog, totalMacros, macrosGoals, input);
      setResponse(response.data.answer);
      setInput('');
    } catch (error) {
      console.error('Error asking misterfit:', error);
      setResponse('Sorry, I could not process your question.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVoiceButtonClick = async () => {
    if (isRecording) {
      setIsRecording(false);
      setAudioStream(null);
      try {
        const audioBlob = await voiceService.stopRecording();
        const response = await apiTranscribeAudio(audioBlob);
        setInput(response.data.text);
      } catch (error) {
        console.error('Error al procesar audio:', error);
        alert(`Error al procesar el audio: ${error.error || error.message}`);
      }
    } else {
      try {
        const stream = await voiceService.requestMicrophonePermission();
        if (stream) {
          setAudioStream(stream);
          const started = await voiceService.startRecording((error) => {
            alert(error);
            setIsRecording(false);
            setAudioStream(null);
          });
          if (started) {
            setIsRecording(true);
          }
        }
      } catch (error) {
        console.error('Error al iniciar grabación:', error);
        setIsRecording(false);
        setAudioStream(null);
      }
    }
  };

  const getFoodGroupIcon = (groupName) => {
    const icons = {
      'Vegetables': 'fa-carrot',
      'Fruit': 'fa-apple-alt',
      'Whole Grains': 'fa-bread-slice',
      'Dairy or Alternatives': 'fa-cheese',
      'Olive Oil': 'fa-oil-can',
      'Legumes': 'fa-seedling',
      'Fish/White Meat/Eggs': 'fa-fish',
      'Occasionally': 'fa-cookie-bite'
    };
    return icons[groupName] || 'fa-utensils';
  };

  const renderFoodGroup = (title, group) => {
    if (!group) return null;

    return (
      <div className="food-group mb-2">
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            <h6 className="mb-0">
              <i className={`fas ${getFoodGroupIcon(title)} me-2`}></i>
              {title}
            </h6>
          </div>
          <div className="col-md-3 d-none d-md-block">
            <span className="metric-label">
              <i className={`fas fa-check-circle ${group.quantity ? 'text-success' : 'text-danger'}`}></i>
              {' '}Cantidad
            </span>
          </div>
          {group.variety !== undefined && (
            <div className="col-md-3 d-none d-md-block">
              <span className="metric-label">
                <i className={`fas fa-check-circle ${group.variety ? 'text-success' : 'text-danger'}`}></i>
                {' '}Variedad
              </span>
            </div>
          )}
        </div>
        {group.comment && (
          <div className="row">
            <div className="col-12">
              <p className="mb-0 mt-2">{group.comment}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getGroupsWithIssues = (analysis) => {
    if (!analysis) return { bothIssues: [], oneIssue: [], noIssues: [] };

    const groups = [
      { name: 'Vegetables', data: analysis.vegetables },
      { name: 'Fruit', data: analysis.daily_fruit },
      { name: 'Whole Grains', data: analysis.whole_grains },
      { name: 'Dairy or Alternatives', data: analysis.dairy_or_alternatives },
      { name: 'Olive Oil', data: analysis.olive_oil },
      { name: 'Legumes', data: analysis.legumes },
      { name: 'Fish/White Meat/Eggs', data: analysis.fish_white_meat_eggs },
      { name: 'Occasionally', data: analysis.occasionally }
    ];

    const bothIssues = [];
    const oneIssue = [];
    const noIssues = [];

    groups.forEach(group => {
      if (!group.data) return;

      const hasVarietyMetric = group.data.variety !== undefined;
      const issues = (hasVarietyMetric ? !group.data.variety : false) + !group.data.quantity;

      if (issues === 2 || (!hasVarietyMetric && issues === 1)) {
        bothIssues.push(group);
      } else if (issues === 1) {
        oneIssue.push(group);
      } else {
        noIssues.push(group);
      }
    });

    return { bothIssues, oneIssue, noIssues };
  };

  const toggleGroupExpansion = (groupName) => {
    if (expandedGroups.includes(groupName)) {
      setExpandedGroups(expandedGroups.filter(name => name !== groupName));
    } else {
      setExpandedGroups([...expandedGroups, groupName]);
    }
  };

  return (
    <div className="mr-fit-container mt-5">
      {(isAnalysisLoading || isWeeklyAnalysisLoading) ? (
        <div className="daily-analysis">
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Cargando...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="daily-analysis">
          {/* Análisis Diario y Semanal */}
          <div className="rounded">
            {/* Tips for Today */}
            {dailyAnalysis && dailyAnalysis.improvements && dailyAnalysis.improvements.length > 0 && (
              <div className="food-groups-section mb-4">
                <h5 className="text-primary mb-3">Tips for Today</h5>
                <ul className="list-unstyled analysis-list">
                  {dailyAnalysis.improvements.map((punto, index) => (
                    <li key={index} className="mb-2">
                      <i className="fas fa-lightbulb me-2 text-primary"></i>
                      <span className="daily-comment">{punto}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Weekly Analysis */}
            {weeklyAnalysis && (
              <div className="food-groups-section">
                <h5 className="text-primary mb-3">Weekly Analysis</h5>
                <div className="food-groups-container">
                  {[
                    ...getGroupsWithIssues(weeklyAnalysis).bothIssues,
                    ...getGroupsWithIssues(weeklyAnalysis).oneIssue,
                    ...getGroupsWithIssues(weeklyAnalysis).noIssues
                  ].map(group => (
                    <div key={group.name} className="food-group">
                      <div 
                        className="food-group-header" 
                        onClick={() => toggleGroupExpansion(group.name)}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="mb-0">
                            <i className={`fas ${getFoodGroupIcon(group.name)} me-2`}></i>
                            {group.name}
                            <i className={`fas fa-chevron-${expandedGroups.includes(group.name) ? 'up' : 'down'} ms-2`}></i>
                          </h6>
                          <div className="metrics-container">
                            <span className="metric-label me-2">
                              <i className={`fas fa-check-circle ${group.data.quantity ? 'text-success' : 'text-danger'}`}></i>
                              {' '}Cantidad
                            </span>
                            {group.data.variety !== undefined && (
                              <span className="metric-label">
                                <i className={`fas fa-check-circle ${group.data.variety ? 'text-success' : 'text-danger'}`}></i>
                                {' '}Variedad
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {expandedGroups.includes(group.name) && group.data.comment && (
                        <div className="food-group-content">
                          <p className="mb-0">{group.data.comment}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Ask Mr. Fit */}
          <div className="daily-analysis mb-4 pt-3">
            <h4>Ask misterfit.ai</h4>
            <form onSubmit={handleSubmit} className="mb-3">
              <div className="input-group position-relative">
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  className={`form-control ${isRecording ? 'recording' : ''}`}
                  placeholder="Ask me anything about your diet..."
                  disabled={isLoading || isRecording}
                />
                {isRecording && (
                  <>
                    <AudioVisualizer isRecording={isRecording} stream={audioStream} />
                    <div className="recording-controls">
                      <RecordingTimer isRecording={isRecording} />
                    </div>
                  </>
                )}
                <button 
                  type="button"
                  className="btn-icon voice-input-button me-4"
                  onClick={handleVoiceButtonClick}
                  disabled={isLoading}
                  title={isRecording ? "Stop recording" : "Start recording"}
                >
                  {isRecording ? (
                    <i className="fas fa-stop-circle text-danger"></i>
                  ) : (
                    <i className="fas fa-microphone"></i>
                  )}
                </button>
                <button 
                  type="submit" 
                  className="btn btn-icon ms-2" 
                  disabled={isLoading || isRecording}
                  title="Send question"
                >
                  {isLoading ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  ) : (
                    <i className="fas fa-paper-plane"></i>
                  )}
                </button>
              </div>
            </form>
          

            {response && (
              <div className="response-container mt-3 p-3">
                {typeof response === 'string' ? (
                  <div 
                    dangerouslySetInnerHTML={{ __html: response }}
                    className="mb-0"
                  />
                ) : (
                  <p>Error: La respuesta no es un texto válido.</p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MrFitAdvisor; 