import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { apiLogin } from '../api';

function Login({ onLogin, setLoginData }) {
  const [loginData, setLocalLoginData] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  const handleLoginDataChange = (e) => {
    const { name, value } = e.target;
    setLocalLoginData({ ...loginData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await apiLogin(loginData);
    const data = await response.json();
    
    if (response.ok) {
      localStorage.setItem('userToken', data.token);
      localStorage.setItem('userEmail', loginData.email);
      onLogin();
      setLoginData(loginData);
      navigate('/');
    } else {
      alert(data.error);
    }
  };

  return (
    <form onSubmit={handleLogin} className="mt-5 mb-3">
      <div className="mb-3">
        <label htmlFor="email" className="form-label">Email</label>
        <input type="email" className="form-control" id="email" name="email" value={loginData.email} onChange={handleLoginDataChange} required />
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label">Password</label>
        <input type="password" className="form-control" id="password" name="password" value={loginData.password} onChange={handleLoginDataChange} required />
      </div>
      <button type="submit" className="btn btn-primary">Log In</button>
      <div className="mt-2">
        <Link to="/signup">Not a member? Sign up</Link>
      </div>
    </form>
  );
}

export default Login;
