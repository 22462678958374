import React, { useState } from 'react';
import {Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/App.css';

import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import SignUp from './components/SignUp';
import MyGoals from './components/MyGoals';
import MyFood from './components/MyFood';
import MyFavoriteMeals from './components/MyFavoriteMeals';
import Settings from './components/Settings';
import SelectFavoriteMeal from './components/SelectFavoriteMeal';
import NavBar from './components/NavBar';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const token = localStorage.getItem('userToken');
    return !!token;
  });
  const [loginData, setLoginData] = useState(() => {
    const email = localStorage.getItem('userEmail');
    return { 
      email: email || '', 
      password: '' 
    };
  });
  const [logUpdateCounter, setLogUpdateCounter] = useState(0);
  const navigate = useNavigate();

  const handleLogout = () => {
    setIsLoggedIn(false);
    setLoginData({ email: '', password: '' });
    localStorage.removeItem('userToken');
    localStorage.removeItem('userEmail');
    navigate('/');
  };

  return (
    <div className="App container">
    <header className="App-header">
      <div className="main-container">
        
        <NavBar 
          isLandingPage={!isLoggedIn && window.location.pathname === '/'}
          isLoggedIn={isLoggedIn}
          onLogout={handleLogout}
          isAuthPage={window.location.pathname === '/login' || window.location.pathname === '/signup'}
        />

      <Routes>
        <Route path="/" element={isLoggedIn ? 
          <Dashboard 
            navigate={navigate} 
            userEmail={loginData.email} 
            isLoggedIn={isLoggedIn} 
            setIsLoggedIn={setIsLoggedIn} 
            loginData={loginData} 
            setLoginData={setLoginData}
            handleLogout={handleLogout}
            logUpdateCounter={logUpdateCounter}
          /> : 
          <LandingPage />
        } />
        <Route path="/login" element={<Login onLogin={() => setIsLoggedIn(true)} setLoginData={setLoginData} />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/goals" element={isLoggedIn ? <MyGoals email={loginData.email} /> : <Navigate to="/login" />} />
        <Route path="/myfood" element={isLoggedIn ? <MyFood email={loginData.email} /> : <Navigate to="/login" />} />
        <Route path="/settings" element={isLoggedIn ? <Settings email={loginData.email} /> : <Navigate to="/login" />} />
        <Route path="/my-favorite-meals" element={isLoggedIn ? <MyFavoriteMeals email={loginData.email} /> : <Navigate to="/login" />} />
        <Route path="/select-favorite-meal/:date/:mealType" element={isLoggedIn ?<SelectFavoriteMeal email={loginData.email}setLogUpdateCounter={setLogUpdateCounter}/> : <Navigate to="/login" />} />
      </Routes>
    </div>
  </header>
  </div>
  );
}

export default App;